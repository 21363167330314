import React from "react";

const GOOCHVALLEYCITYCOLLEGE = [
  "Jenna Rose (BOF)",
  "Anna Thiele",
  "Ty Tracht",
  "Matt Zamora",
  "Hannah Rick",
  "Austin Powell",
  "Evan August",
  "Hunter Levy",
  "Mason Maloney",
  "Buddy Levy",
  "Steve Tracht",
  "Kyle Peterson",
  "Grubby",
  "Ian Rohnert",
  "Connor Nogales",
  "Michael Lambert",
  "Luke",
  "Beth Norton",
  "David Slemmer",
];

function Sponsors() {
  return (
    <div>
      <div className="text-center mt-4">
        <p className="text-2xl">We would like to thank our sponsors for their generous support.</p>
        <p className="text-2xl">Without them, this project would all be for nothing</p>
      </div>
      <div className="text-center mt-4">
        <ul>
          {GOOCHVALLEYCITYCOLLEGE.map((name) => (
            <li key={name} className="text-xl">
              {name}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default Sponsors;
